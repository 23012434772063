<template>
  <div class="container">
    <nf-breadcrumb :breadList="breadList"></nf-breadcrumb>

    <div class="nf-tabs">
      <div class="nf-tabs-nav">
        <router-link
          v-for="(nav, index) in tabsNav"
          :key="index"
          class="nf-tabs-nav-label"
          :class="{'is-active': activeNav === index}"
          :to="`${nav.url}`"
        >
          <i v-if="index==0" class="icon icon-await"></i>
          <i v-if="index==1" class="icon icon-processing"></i>
          <i v-if="index==2" class="icon icon-draft"></i>
          <span>{{nav.name}}</span>
        </router-link>
      </div>
      <div class="nf-tabs-content">
        <router-view />
      </div>
    </div>
    <el-dialog
      title="认证信息"
      :visible.sync="centerDialogVisible"
      width="30%"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :show-close="false"
      center
    >
      <span>你还没有进行账户认证</span>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="centerDialogVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="centerDialogVisibles()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="权限"
      :visible.sync="centerDialogVisiblese"
      width="30%"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :show-close="false"
      center
    >
      <span>您还没有进行实验室管理的权限</span>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="centerDialogVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="centerDialogVisibles()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import nfBreadcrumb from "@/component/breadcrumb/breadcrumb";
import config from '@/utils/config';

export default {
  components: {
    nfBreadcrumb
  },

  data() {
    return {
      centerDialogVisible: false,
      centerDialogVisiblese: false,
      activeNav: 0,
      tabsNav: [
        {
          name: "样品管理",
          url: "sampleManage"
        },
        {
          name: "资源管理",
          url: "resourcesManage"
        },
        {
          name: "统计报表",
          url: "statisticalReport"
        }
      ],
      breadList: [
        {
          name: "实验室管理"
        }
      ]
    };
  },

  created() {
    if (location.href.indexOf('localhost')<0){
      this.findMemberAuthByUserId();
    }
    this.findMemberAuthByUserId();
    const path = this.$route.path;
    if (path === "/laboratoryInfo/sampleManage") {
      this.activeNav = 0;
    } else if (path === "/laboratoryInfo/resourcesManage") {
      localStorage.removeItem("currentNav");
      this.activeNav = 1;
    } else if (path === "/laboratoryInfo/statisticalReport") {
      this.activeNav = 2;
    }
  },

  methods: {
    centerDialogVisibles() {
       this.$router.push("/index");
    },
    // async isNanFans() {
    //   const api = this.$fetchApi.getQueryByPhone.api;
    //   const res = await this.$fetchData.fetchPost({}, api + `?phone=${this.phoneNum}`, 'json');

    //   if (res.code === '200' && res.result.systemOrganizationId === '02b152594cce40c5a8b996aebf0c3084') {
    //     this.isNanFan = 1;
    //   } else {
    //     this.isNanFan = 2;
    //   }
    // },
    async findMemberAuthByUserId() {
      const api = this.$fetchApi.findMemberAuthByUserId.api;
      const data = await this.$fetchData.fetchPost({}, api, "json");
      if (data.code === "200"){
        if (data.result.status === 2){
          this.queryUserRank();
        } else {
          this.centerDialogVisible = true;
        }
      } else {
        this.centerDialogVisible = true;
      }
    },
    async queryUserRank() {
      const api = this.$fetchApi.roleConfirm.api;
      const phone = JSON.parse(window.localStorage.getItem('loginaccount')).phoneNumber;
      const data = await this.$fetchData.fetchPost({}, api + `?phone=${phone}`, "json");
      if (data.code === "200" && data.result.systemOrganizationId !== '02b152594cce40c5a8b996aebf0c3084') {
        this.centerDialogVisiblese = true;
        // if (data.result.type == 0) {
        //   this.centerDialogVisible = true;
        // } else {
        //   if (data.result.rank == 0) {
        //     this.centerDialogVisiblese = true;
        //   } else {
        //     return;
        //   }
        // }
      }
    }
  }
};
</script>
<style lang="less" scoped>
.nf-tabs {
  max-width: 1200px;
  min-height: 600px;
  display: flex;
  margin: 0 auto;

  .icon {
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    background: none no-repeat center;
    background-size: 100%;
    margin-right: 4px;

    &-await {
      background-image: url("../../assets/images/laboratoryInfo/icon_yp.png");
    }

    &-processing {
      background-image: url("../../assets/images/laboratoryInfo/icon_zy.png");
    }

    &-draft {
      background-image: url("../../assets/images/laboratoryInfo/icon_tj.png");
    }
  }

  &-nav {
    min-width: 170px;
    max-width: 170px;
    margin-right: 10px;
    background: #ffffff;
    box-shadow: 2px 0px 3px 0px rgba(38, 90, 225, 0.15);

    &-label {
      display: block;
      height: 60px;
      line-height: 60px;
      padding: 0 48px 0 24px;
      font-weight: 700;
      color: #3380fe;

      &.is-active {
        background: url("../../assets/images/officialDocument/pic_tab_nav_bg.png")
          center no-repeat;
        background-size: 100%;
      }
    }
  }

  &-content {
    width: 100%;
  }
}
</style>
